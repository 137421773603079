import React, { useState, useEffect } from 'react';
import './ShopBy.css';

import ShopByMenu from './ShopByMenu';
import ProductList from './ProductList';
import ShopBy from '../../../../types/ShopBy';
import { getShopBy } from '../../../../api/requests/ShopByRequests';
import { getProductsByShopBy, getProductsWithoutProductType } from '../../../../api/requests/ProductRequests';
import { Product } from '../../../../types/Products';
import ProductsParType from './ProductsParType';

const ShopByComponent: React.FC = () => {
  const [items, setItems] = useState<ShopBy[]>([]); 
  const [activeItemId, setActiveItemId] = useState<string | null>(null);  
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);  
  const [popularProducts, setPopularProducts] = useState<Product[]>([]); // Updated to be dynamic
  const [productsPerSection, setProductsPerSection] = useState<number>(6); // Dynamically calculated number of products per section

useEffect(() => {
    // Fetch products without productTypeId
    const fetchDefaultProducts = async () => {
      try {
        const products = await getProductsWithoutProductType(); // Fetch products without a specific productType
        console.log("Produits récupérés sans productType:", products);
        setFilteredProducts(products); // Update the filtered products state
        setPopularProducts(products.slice(0, 5)); // Set first 5 products as popular, or change based on your logic
      } catch (error) {
        console.error("Erreur lors de la récupération des produits sans productType:", error);
      }
    };

    // Fetch shop data
    const fetchShopByData = async () => {
      try {
        const data = await getShopBy();
        setItems(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données ShopBy", error);
      }
    };

    fetchDefaultProducts(); // Fetch default products
    fetchShopByData();  // Fetch ShopBy data
  }, []);  // This useEffect triggers only once

  useEffect(() => {
    if (activeItemId) {
      fetchProductsForShopBy(activeItemId);  // Fetch products for the selected ShopBy category
    }
  }, [activeItemId]);  // Triggers when activeItemId changes

  const fetchProductsForShopBy = async (shopById: string) => {
    try {
      const products = await getProductsByShopBy(shopById);
      setFilteredProducts(products);  // Update the filtered products state by ShopBy
      setPopularProducts(products.slice(0, 5)); // Update popular products when new data is fetched
    } catch (error) {
      console.error("Erreur lors de la récupération des produits pour ShopBy", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
        if (window.innerWidth < 748) {
          setProductsPerSection(2); // 1 product per row on small screens
        } else if (window.innerWidth >= 748 && window.innerWidth <= 992) {
          setProductsPerSection(3); // 2 products per row on medium screens
        } else if (window.innerWidth > 992 && window.innerWidth <= 1140) {
          setProductsPerSection(4); // 3 products per row on larger screens
        } else if(window.innerWidth > 1140 && window.innerWidth <= 1493){
          setProductsPerSection(5); // 4 products per row on very large screens
        }else if(window.innerWidth > 1493 && window.innerWidth <= 1568){
          setProductsPerSection(5);
        }
        else{
          setProductsPerSection(6)
        };
    };

    // Call resize handler on initial load and whenever window resizes
    window.addEventListener('resize', handleResize);
    handleResize();  // Trigger resize handler on load

    return () => window.removeEventListener('resize', handleResize);  // Clean up the event listener
  }, []);

  const handleClick = (id: string) => {
    setActiveItemId(id === activeItemId ? null : id);  // Toggle active/inactive state
  };

  // Function to split products into sections
  const splitProductsIntoSections = (products: Product[], sectionSize: number) => {
    const sections = [];
    for (let i = 0; i < products.length; i += sectionSize) {
      sections.push(products.slice(i, i + sectionSize));
    }
    return sections;
  };

  // Split filteredProducts into sections, but keep all products in one section if there's only one line
  const productSections = filteredProducts.length <= productsPerSection ? [filteredProducts] : splitProductsIntoSections(filteredProducts, productsPerSection);

  return (
    <div className="shop-by-interface">
      <div className="shop-by-menu-container">
        <ShopByMenu items={items} activeItemId={activeItemId} onClick={handleClick} />
      </div>

      {/* Loop through each section of products */}
      {productSections.map((section, index) => (
        <div key={index}>
          {/* Render product list for this section */}
          <ProductList products={section} productsPerSection={productsPerSection} />

          {/* Display ProductsParType after every product section, unless it's the last section */}
          {index < productSections.length - 1 && <ProductsParType products={popularProducts} />}
        </div>
      ))}

      {/* Display popular products */}
      {productSections.length === 1 && <ProductsParType products={popularProducts} />}
    </div>
  );
};

export default ShopByComponent;
