import { Product } from "../../types/Products";
import ShopBy from "../../types/ShopBy";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "products";

// Récupérer tous les produits
export const getProducts = async (): Promise<Product[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Product[] }>(API_ENDPOINT);
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la récupération des produits:", error);
    throw error;
  }
};
export const getProductsByProductTypeId = async (productTypeId: string): Promise<Product[]> => {
  try {
    console.log(`Requête pour les produits avec productTypeId: ${productTypeId}`);
    const response = await HTTP_CLIENT.get<Product[]>(`${API_ENDPOINT}/byType/${productTypeId}`);
    console.log(`Réponse de l'API pour ${productTypeId}:`, response.data);
    
    // Vérification que la réponse n'est pas vide
    if (!response?.data || response.data.length === 0) {
      console.error(`Aucun produit trouvé pour le type ${productTypeId}`);
      return []; // Si la réponse est vide ou mal formatée, on retourne un tableau vide
    }

    return response.data;  // Retour des produits récupérés
  } catch (error) {
    console.error(`Erreur lors de la récupération des produits pour le type ${productTypeId}:`, error);
    throw error;
  }
};

// Récupérer les produits sans productType
export const getProductsWithoutProductType = async (): Promise<Product[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Product[] }>(`${API_ENDPOINT}/noType`);
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la récupération des produits sans productType:", error);
    throw error;
  }
};


// Récupérer un produit par son ID
export const getProductById = async (id: string): Promise<Product> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Product }>(`${API_ENDPOINT}/${id}`);
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la récupération du produit:", error);
    throw error;
  }
};

// Créer un nouveau produit avec media
export const createProduct = async (formData: FormData): Promise<Product> => {
  try {
    const response = await HTTP_CLIENT.post<{ data: Product }>(API_ENDPOINT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la création du produit:", error);
    throw error;
  }
};

// Mettre à jour un produit existant
export const updateProduct = async (id: string, formData: FormData): Promise<Product> => {
  try {
    const response = await HTTP_CLIENT.put<{ data: Product }>(`${API_ENDPOINT}/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la mise à jour du produit:", error);
    throw error;
  }
};

// Supprimer un produit
export const deleteProduct = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression du produit:", error);
    throw error;
  }
};

// Récupérer les produits par ShopBy
export const getProductsByShopBy = async (shopById: string): Promise<Product[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Product[] }>(`${API_ENDPOINT}/shopby/${shopById}`);
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la récupération des produits par ShopBy:", error);
    throw error;
  }
};

// Récupérer les produits par Subcategory
export const getProductsBySubcategory = async (subcategoryId: string): Promise<Product[]> => {
  console.log("Fetching products for subcategory ID:", subcategoryId);
  try {
    // Récupère directement le tableau de produits
    const response = await HTTP_CLIENT.get<Product[]>(`${API_ENDPOINT}/subcategory/${subcategoryId}`);
    return response.data;  // Récupère directement le tableau de produits
  } catch (error) {
    console.error("Erreur lors de la récupération des produits par Subcategory:", error);
    throw error;
  }
};

export const getShopByForProduct = async (productId: string): Promise<ShopBy[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: ShopBy[] }>(`${API_ENDPOINT}/${productId}/shopby`);
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la récupération des ShopBy pour le produit:", error);
    throw error;
  }
};

// Récupérer les produits populaires
export const getPopularProducts = async (): Promise<Product[]> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: Product[] }>(`${API_ENDPOINT}/popular`);
    return response.data.data; // Access the data property of ApiResponse
  } catch (error) {
    console.error("Erreur lors de la récupération des produits populaires:", error);
    throw error;
  }
};
