import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Logo, SearchBar, CartButton, DeliverySection } from '../TopLine/TopLine';
import { useCart } from '../../CartContext';
import { itemsToSearch } from '../TopLine/itemsToSearch';
import CartModal from '../../Cart';
import MenuIcon from '../Navbar/MenuIcon';

import Dropdown from '../../../Dropdown/Dropdown';

import CreateAccount from '../Account/CreateAccount/CreateAccount';
import SignInModal from '../Account/SignInModal/SignInModal';
import ModalWindow from '../Delivery/Delivery';
import { getNavItems, getNavItemsByType } from '../../../../api/requests/navItemRequests';
import { baseUrl } from '../../../../api/client';

interface NavItem {
  id: string;
  label: string;
  icon: string;
}

const Navbar2: React.FC = () => {
  const [navbar, setNavbar] = useState<NavItem[]>([]); // L'état pour les items de navigation
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isCartModalOpen, setIsCartModalOpen] = useState<boolean>(false);
  const [dropdownTop, setDropdownTop] = useState<number>(0);
  const [navItemId, setNavItemId] = useState<string | null>(null);
  const [dropdownTimeout, setDropdownTimeout] = useState<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const { cartItems } = useCart();


  const formatLabelForUrl = (label: string) => {
    return label
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/&/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };
  

  // Fonction pour récupérer les NavItems via l'API
  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        // Fetch NavItems by type "RESIDENTIAL"
        const items = await getNavItemsByType("RESIDENTIAL");
        setNavbar(items);
      } catch (error) {
        console.error("Erreur lors de la récupération des NavItems:", error);
      }
    };

    fetchNavItems(); // Fetch NavItems on component mount
  }, []); // Only run once on mount

  useEffect(() => {


    const updateDropdownTop = () => {
      const navbarElement = document.querySelector('.navbar');
      if (navbarElement) {
        const rect = navbarElement.getBoundingClientRect();
        setDropdownTop(rect.bottom); // Le bas du navbar
      }
    };

    updateDropdownTop(); // Calcul initial
    window.addEventListener('resize', updateDropdownTop);
    window.addEventListener('scroll', updateDropdownTop);

    return () => {
      window.removeEventListener('resize', updateDropdownTop);
      window.removeEventListener('scroll', updateDropdownTop);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuToggle = () => setIsMenuOpen((prev) => !prev);

  /*const handleMouseEnter = (id: number) => {
    if (!isMobile) {
      setActiveDropdown(id);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setActiveDropdown(null);
    }
  };*/
  const handleMouseEnter = (id: number) => {
    if (!isMobile) {
      setActiveDropdown(id);
    }
  };
  
  const handleMouseLeave = (id: number) => {
    if (!isMobile) {
      // Retarder la fermeture du dropdown lorsque la souris quitte le parent
      if (dropdownTimeout) clearTimeout(dropdownTimeout);
      setDropdownTimeout(setTimeout(() => {
        if (activeDropdown === id) {
          setActiveDropdown(null); // Fermer le dropdown si on quitte le parent
        }
      }, 300)); // Vous pouvez ajuster le délai ici
    }
  };
  
  // Ne pas fermer le dropdown quand la souris entre dans le dropdown
  const handleDropdownMouseEnter = () => {
    if (dropdownTimeout) clearTimeout(dropdownTimeout); // Annule le délai de fermeture si on entre dans le dropdown
  };
  
  const handleDropdownMouseLeave = () => {
    // Retarder la fermeture quand la souris quitte le dropdown
    if (dropdownTimeout) clearTimeout(dropdownTimeout);
    setDropdownTimeout(setTimeout(() => {
      setActiveDropdown(null); // Fermer le dropdown après le délai
    }, 300)); // Ajustez le délai si nécessaire
  };
  
  
  const handleDropdownClick = (
    e: React.MouseEvent,
    id: number,
    label: string
  ) => {
    e.preventDefault();

    // Mettre à jour l'ID du NavItem sélectionné
    setNavItemId(navbar[id].id);

    // Réinitialiser le dropdown actif
    setActiveDropdown(null);

    // Utiliser la fonction de formatage pour le label
    const formattedLabel = formatLabelForUrl(label);

    console.log("Navigating to department with formatted label:", formattedLabel);

    // Navigation vers la nouvelle page avec le label formaté
    navigate(`/department/${formattedLabel}`);

    // Fermer le menu
    setIsMenuOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value) {
      const results = itemsToSearch.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
  };

  const handleSearchFocus = () => setIsFocused(true);

  const handleSearchBlur = () => {
    if (!searchValue) setIsFocused(false);
  };

  const openAddressModal = () => setIsAddressModalOpen(true);

  const closeAddressModal = () => setIsAddressModalOpen(false);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-white" style={{ backgroundColor: '#ffffff' }}>
        <div className="container-fluid d-flex flex-column">
          {/* Top Navbar (Logo, Menu, Cart) */}
          <div className="navbar-top d-flex align-items-center justify-content-between w-100">
            {isMobile && <MenuIcon isMenuOpen={isMenuOpen} onToggle={handleMenuToggle} />}
            {isMobile && <Logo />}
            {isMobile && (
              <div className="cart-button-container">
                <CartButton cartItems={cartItems} onClick={() => setIsCartModalOpen(true)} />
              </div>
            )}
          </div>

          {/* Search Bar for Mobile */}
          {isMobile && (
            <div className="search-bar-container w-100">
              <SearchBar
                searchValue={searchValue}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                clearSearch={clearSearch}
                isFocused={isFocused}
              />
            </div>
          )}

          {/* Mobile Menu */}
          <div className={`navbar-menu ${isMenuOpen ? 'show' : 'hide'}`} id="navbarNav">
            {isMenuOpen && isMobile && (
              <>
                <button className="close-btn" onClick={handleMenuToggle}>
                  &times;
                </button>
                <div className="delivery-section">
                  <img
                    src="/favicon.ico"
                    alt="Go to homepage"
                    width="23"
                    height="23"
                    className="delivery-icon"
                  />
                  <DeliverySection onOpenAddressModal={openAddressModal} />
                </div>

                {/* Sign In and Create Account Buttons */}
                <div className="account-popup-menu">
                  <button className="account-link" onClick={() => setIsSignInModalOpen(true)}>
                    Sign In
                  </button>
                  <span className="or-text">or</span>
                  <button className="account-link" onClick={() => setIsCreateAccountModalOpen(true)}>
                    Create an Account
                  </button>
                </div>
              </>
            )}

            {/* Navbar Items */}
            <ul className="navbar-nav ms-auto">
              {navbar.map((item, index) => (
                 <li
                 className="nav-item"
                 key={index}
                 onMouseEnter={() => handleMouseEnter(index)}
                 onMouseLeave={() => handleMouseLeave(index)}
               >
                 <Link
                   to="#"
                   className="nav-link text-dark d-flex flex-column align-items-center"
                   onClick={(e) => handleDropdownClick(e, index, item.label)}
                   aria-haspopup="true"
                   aria-expanded={activeDropdown === index ? 'true' : 'false'}
                 >
                   <div className="icon-container">
                     <img src={item.icon} alt={item.label} className="nav-icon" />
                   </div>
                   <div className="label-button-container">
                     <span className="nav-label">{item.label}</span>
                     <button
                       className={`GlobalNav_nav_item_caret ${activeDropdown === index ? 'open' : ''}`}
                       type="button"
                       aria-haspopup="true"
                       aria-expanded={activeDropdown === index ? 'true' : 'false'}
                     >
                       <img
                         src="/assets/SVG/chevron.png"
                         alt="Chevron"
                         className={`chevron-icon ${activeDropdown === index ? 'open' : ''}`}
                       />
                     </button>
                   </div>
                 </Link>
               
                 {/* Dropdown uniquement sur les écrans de bureau */}
                 {!isMobile && activeDropdown === index && (
                   <div
                     className="dynamic-dropdown"
                     style={{
                       top: `${dropdownTop}px`,
                     }}
                     onMouseEnter={handleDropdownMouseEnter}
                     onMouseLeave={handleDropdownMouseLeave}
                   >
                     <Dropdown
                       navItemId={navbar[activeDropdown]?.id || ""}
                       closeDropdown={() => setActiveDropdown(null)}
                     />
                   </div>
                 )}
               </li>
              ))}
            </ul>

          </div>
        </div>
      </nav>

      {/* Modals */}
      <SignInModal
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <CreateAccount
        isOpen={isCreateAccountModalOpen}
        onClose={() => setIsCreateAccountModalOpen(false)}
      />
      <CartModal isOpen={isCartModalOpen} onClose={() => setIsCartModalOpen(false)} />
      {isAddressModalOpen && <ModalWindow onClose={closeAddressModal} />}

    </header>
  );
};

export default Navbar2;
