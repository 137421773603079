import React, { useState, useEffect } from 'react';
import './Product.css';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import { useCart } from '../HomePage/CartContext';
import { Product as ProductType } from '../../types/Products';  
import { getShopByForProduct } from '../../api/requests/ProductRequests';
import { baseUrl } from '../../api/client';

interface ProductProps {
  products: ProductType[];  // Liste des produits passés en prop
}

const Product: React.FC<ProductProps> = ({ products }) => {
  const { cartItems, addToCart, updateQuantity, removeFromCart } = useCart();
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [notification, setNotification] = useState<string | null>(null);
  const [shopByLabels, setShopByLabels] = useState<{ [productId: string]: { label: string, color: string }[] }>({});
  const [loadingShopBy, setLoadingShopBy] = useState<boolean>(true);  // Indicateur de chargement
  const [errorLoadingShopBy, setErrorLoadingShopBy] = useState<string | null>(null); // Pour afficher une erreur

  const productsPerRow = 4; // Exemple de nombre de produits par ligne
  const currentIndex = 0; // Index de départ (à adapter si pagination)
  const displayedProducts = products.slice(currentIndex, currentIndex + productsPerRow);
 
  useEffect(() => {

    const fetchShopByForProduct = async (productId: string) => {
      try {
        console.log(`Fetching ShopBy for product ${productId}`);
        const productShopBy = await getShopByForProduct(productId);
        console.log(`Received ShopBy for product ${productId}:`, productShopBy); // Vérifier les données retournées
    
        if (productShopBy && productShopBy.length > 0) {
          setShopByLabels(prev => ({
            ...prev,
            [productId]: productShopBy.map(shopBy => ({
              label: shopBy.label,
              color: shopBy.color || 'black',
            })),
          }));
        } else {
          console.error(`Aucun label trouvé pour le produit ${productId}`);
        }
      } catch (err) {
        console.error(`Erreur lors du chargement des ShopBy pour le produit ${productId}:`, err);
        setErrorLoadingShopBy(`Erreur lors du chargement des labels pour le produit ${productId}.`);
      }
    };
    
    
    const fetchShopByData = async () => {
      setLoadingShopBy(true);
      try {
        if (!products || products.length === 0) return;
        
        // Récupérer les labels pour tous les produits en parallèle
        const allShopBy = await Promise.all(
          products.map(product => fetchShopByForProduct(product.id))
        );
        
        // Mettre à jour l'état avec tous les labels récupérés
        // Si vous avez besoin de manipuler les données après la récupération, faites-le ici
      } catch (err) {
        setErrorLoadingShopBy('Erreur lors du chargement des labels.');
      } finally {
        setLoadingShopBy(false);
      }
    };
    
  
    fetchShopByData();
  }, [products]);  // Relancer l'effet quand les produits changent

  // Fonction pour gérer l'ajout au panier
  const handleAddToCart = (product: ProductType, quantity: number) => {
    const mediaUrl = product.media;

    const cartItem = {
      id: product.id,
      name: product.name,
      quantity,
      price: parseFloat(product.price.toString()), // Assurez-vous que price est un nombre
      media: mediaUrl, 
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopByIds: product.shopByIds,  
    };
    addToCart(cartItem, quantity);
    setNotification(`${quantity} x ${product.name} ajouté au panier !`);
    setTimeout(() => setNotification(null), 3000);
  };

  // Fonction pour mettre à jour la quantité d'un produit dans le panier
  const handleQuantityChange = (productName: string, quantity: number) => {
    setQuantities((prev) => ({ ...prev, [productName]: quantity }));

    const product = products.find((p) => p.name === productName);
    if (product) {
      if (quantity > 0) {
        const cartItemExists = quantities[productName] && quantities[productName] > 0;

        if (!cartItemExists) {
          handleAddToCart(product, quantity);
        }

        updateQuantity(productName, quantity);
      } else {
        removeFromCart(productName);
      }
    }
  };

  // Mettre à jour les quantités au démarrage
  useEffect(() => {
    const updatedQuantities: { [key: string]: number } = {};
    cartItems.forEach(item => {
      updatedQuantities[item.name] = item.quantity;
    });
    setQuantities(updatedQuantities);
  }, [cartItems]);

  return (
    <div>
      {notification && <div className="notification">{notification}</div>}
      {loadingShopBy ? (
        <div>Chargement des informations...</div>
      ) : errorLoadingShopBy ? (
        <div>{errorLoadingShopBy}</div>  // Affichage d'une erreur si présente
      ) : (
        <div className="product-list">
          {products.length > 0 ? (
            products.map((product, index) => (
              <div
                className={`product-item ${index !== displayedProducts.length - 1 ? 'border-right' : ''}`}
                key={product.id}
              >
                <div className="flex justify-center mb-4">
                  {product.media && (product.media.endsWith('.mp4') || product.media.endsWith('.mov')) ? (
                    <video
                      src={product.media}
                      className="product-image"
                      controls
                    />
                  ) : (
                    <img
                      src={product.media}
                      alt={product.name}
                      className="product-image"
                    />
                  )}
                </div>

                <div className="shop-by-labels">
                  {shopByLabels[product.id] && shopByLabels[product.id].length > 0 ? (
                    shopByLabels[product.id].map((shopBy, i) => (
                      <span key={i} style={{ fontSize: 14, color: shopBy.color }}>
                        {shopBy.label}
                        {i !== shopByLabels[product.id].length - 1 && <span className="vertical-divider"> | </span>}
                      </span>
                    ))
                  ) : (
                    <span>Aucun label disponible</span>
                  )}
                </div>

                <div>
                  <p className="product-brand">{product.brand}</p>
                  <p className="product-name">{product.name}</p>
                </div>
                <div>
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="product-unit-size">{product.unitSize}</span>
                    <div className="vertical-line"></div>
                    <span className="original-price">{product.unitPrice} XOF/lb</span>
                  </p>
                </div>
                <div className="product-card-container">
                  <p>
                    <strong className="current-price">{product.price} XOF/ea</strong>
                  </p>
                  <div className="right-container">
                    <QuantitySelector
                      initialQuantity={quantities[product.name] || 0}
                      onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Aucun produit disponible.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
