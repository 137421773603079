import React, { useEffect, useState } from 'react';
import './AllProduct.css';
import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import { Product } from '../../../../types/Products';
import { getProductsByProductTypeId } from '../../../../api/requests/ProductRequests';

import { useParams } from 'react-router-dom';
import { getProductTypeById } from '../../../../api/requests/ProductTypeRequests';

const AllProductsPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]); // Liste des produits
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({}); // Quantités
  const { addToCart, updateQuantity, removeFromCart } = useCart(); // Gestion du panier
  const { productTypeId } = useParams<{ productTypeId: string }>(); // Récupération du paramètre `productTypeId`
  const [productType, setProductType] = useState<any>(null); // État pour le type de produit
  const [shopByLabels, setShopByLabels] = useState<{ [key: string]: { label: string; color: string }[] }>({});
  const [shopByData, setShopByData] = useState<any[]>([]); 

  // Fonction pour ajouter un produit au panier
  const handleAddToCart = (product: Product, quantity: number) => {
    if (quantity <= 0) return; // Ne pas ajouter si la quantité est 0 ou moins
    const mediaUrl = product.media;
    const cartItem = {
      id: product.id.toString(),
      name: product.name,
      quantity,
      price: parseFloat(product.price.toString()),
      media: mediaUrl,
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopByIds: product.shopByIds,
    };
    addToCart(cartItem, quantity);
  };

  // Fonction pour gérer le changement de quantité d'un produit
  const handleQuantityChange = (productName: string, quantity: number) => {
    const product = products.find((p) => p.name === productName);
    if (product) {
      if (quantity > 0) {
        handleAddToCart(product, quantity);
        updateQuantity(productName, quantity);
      } else {
        removeFromCart(productName);
      }
    }
  };

  const getShopByInfo = (shopBy: string) => {
    return shopByData.find(item => item.label === shopBy) || null;
  };

  // Récupérer les produits associés au `productTypeId` et le `productType`
  useEffect(() => {
    if (productTypeId) {
      const fetchProductData = async () => {
        try {
          // Récupérer les informations du productType
          const fetchedProductType = await getProductTypeById(productTypeId);
          console.log('ProductType récupéré :', fetchedProductType);  // Vérifiez les données récupérées
          setProductType(fetchedProductType);  // Mettre à jour l'état avec le productType récupéré

          // Récupérer les produits associés au productTypeId
          const fetchedProducts = await getProductsByProductTypeId(productTypeId);
          console.log('Produits récupérés :', fetchedProducts);  // Vérifiez les données récupérées
          setProducts(fetchedProducts);  // Mettre à jour l'état avec les produits récupérés
        } catch (error) {
          console.error("Erreur lors de la récupération des produits et du productType :", error);
        }
      };

      fetchProductData();
    }
  }, [productTypeId]);

  return (
    <div className="all-products-page">
      {productType && (
        <div className="product-type-section">
          <div className="all-products-image-text-container">
            <div className="all-products-image-column">
              <img src={productType.image} alt={productType.title} className="all-products-image" />
            </div>
            <div className="all-products-text-column">
              <h2>{productType.title}</h2>
              <p>{productType.description}</p>
            </div>
          </div>

          <div className="product-list">
            {products.length > 0 ? (
              products.map((product, index) => (
                <div className={`product-item ${index !== products.length - 1 ? 'border-right' : ''}`} key={product.id}>
                  <div className="flex justify-center mb-4">
                    <img src={product.media} alt={product.name} className="product-image" />
                  </div>
                  <div className="shop-by-labels">
                    {shopByLabels[product.id] && shopByLabels[product.id].length > 0 ? (
                      shopByLabels[product.id].map((shopBy, i) => (
                        <span key={i} style={{ fontSize: 14, color: shopBy.color }}>
                          {shopBy.label}
                          {i !== shopByLabels[product.id].length - 1 && <span className="vertical-divider"> | </span>}
                        </span>
                      ))
                    ) : (
                      <span>Aucun label disponible</span>
                    )}
                  </div>

                  <div>
                    <p className="product-brand">{product.brand}</p>
                    <p className="product-name">{product.name}</p>
                  </div>
                  <div>
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="product-unit-size">{product.unitSize}</span>
                      <div className="vertical-line"></div>
                      <span className="original-price">{product.unitPrice} XOF/lb</span>
                    </p>
                  </div>
                  <div className="product-card-container">
                    <p>
                      <strong className="current-price">{product.price} XOF/ea</strong>
                    </p>
                    <div className="right-container">
                      <QuantitySelector
                        initialQuantity={quantities[product.name] || 0}
                        onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Aucun produit disponible pour ce type.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProductsPage;
