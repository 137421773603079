import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import arrow icons
import './PopularProduct.css';
import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import { Link } from 'react-router-dom';
import { getProductsByProductTypeId, getShopByForProduct } from '../../../../api/requests/ProductRequests';
import { Product } from '../../../../types/Products';
import { getProductTypes } from '../../../../api/requests/ProductTypeRequests';


interface ProductSectionProps {
  productType: { id:string;title: string; description: string; image: string };
  products: Product[];
  currentIndex: number;
  productsPerRow: number;
  shopByLabels: { [key: string]: { label: string; color: string }[] };
  quantities: { [key: string]: number };

  handlePrev: () => void;
  handleNext: () => void;
}

const ProductSection: React.FC<ProductSectionProps> = ({
  productType,
  products,
  currentIndex,
  productsPerRow,
  shopByLabels,
  handlePrev,
  handleNext,
}) => {

  const displayedProducts = products.slice(currentIndex, currentIndex + productsPerRow);

  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const { cartItems, updateQuantity, addToCart, removeFromCart } = useCart();

  const [notification, setNotification] = useState<string | null>(null);

  const handleQuantityChange = (productName: string, quantity: number) => {
    console.log(`handleQuantityChange called with productName: ${productName}, quantity: ${quantity}`);

    // Update the quantities state
    setQuantities((prev) => {
      const updatedQuantities = { ...prev, [productName]: quantity };
      console.log("Updated quantities:", updatedQuantities);
      return updatedQuantities;
    });

    // Find the product from the list
    const normalizedProductName = productName.trim().toLowerCase();
    const product = products.find((p) => p.name.trim().toLowerCase() === normalizedProductName);

    if (product) {
      console.log("Product found:", product);
      if (quantity > 0) {
        const cartItemExists = cartItems.some(item => item.name === productName);

        if (!cartItemExists) {
          handleAddToCart(product, quantity);
        } else {
          console.log("Updating quantity in cart");
          updateQuantity(productName, quantity);
        }
      } else {
        console.log(`Quantity is <= 0, removing item from cart.`);
        removeFromCart(productName);
      }
    } else {
      console.log(`Product ${productName} not found`);
    }
  };

  const handleAddToCart = (product: Product, quantity: number) => {
    console.log(`handleAddToCart called with product: ${product.name}, quantity: ${quantity}`);

    if (quantity <= 0) {
      console.log("Invalid quantity (<= 0), not adding to cart.");
      return;
    }

    const mediaUrl = product.media;
    const shopByIds = shopByLabels[product.id]?.map(shopBy => shopBy.label) || [];

    const cartItem = {
      id: product.id,
      name: product.name,
      quantity,
      price: parseFloat(product.price.toString()),
      media: mediaUrl,
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopByIds,
    };

    addToCart(cartItem, quantity);
    console.log(`${quantity} x ${product.name} added to cart!`);
    setNotification(`${quantity} x ${product.name} added to cart!`);
    setTimeout(() => setNotification(null), 3000);
  };

  useEffect(() => {
    const updatedQuantities: { [key: string]: number } = {};
    cartItems.forEach(item => {
      updatedQuantities[item.name] = item.quantity;
    });
    setQuantities(updatedQuantities);
  }, [cartItems]);

  return (
    <div className="popular-products-container">
      <div className="content-section">
        <div className="text-column">
          <h2>{productType.title}</h2>
          

          <div className="navigation-and-description">
            <p>{productType.description}</p>
            
            {products.length > 0 && (
              <div className="product-navigation">
                <button className="prev-button" onClick={handlePrev} disabled={currentIndex === 0}>
                  <FaChevronLeft />
                </button>
                <button
                  className="next-button"
                  onClick={handleNext}
                  disabled={currentIndex + productsPerRow >= products.length}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
            <Link to={`/products/${productType.id}`} className="view-button mobile-only">View All</Link>
          </div>
          <div className="product-list1">
            {displayedProducts.map((product, index) => (
              <div className={`product-item ${index !== displayedProducts.length - 1 ? 'border-right' : ''}`} key={product.id}>
                <div className="flex justify-center mb-4">
                  <img src={product.media} alt={product.name} className="product-image" />
                </div>
                <div className="shop-by-labels">
                  {shopByLabels[product.id] && shopByLabels[product.id].length > 0 ? (
                    shopByLabels[product.id].map((shopBy, i) => (
                      <span key={i} style={{ fontSize: 14, color: shopBy.color }}>
                        {shopBy.label}
                        {i !== shopByLabels[product.id].length - 1 && <span className="vertical-divider"> | </span>}
                      </span>
                    ))
                  ) : (
                    <span>Aucun label disponible</span>
                  )}

                </div>

                <div>
                  <p className="product-brand">{product.brand}</p>
                  <p className="product-name">{product.name}</p>
                </div>
                <div>
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="product-unit-size">{product.unitSize}</span>
                    <div className="vertical-line"></div>
                    <span className="original-price">{product.unitPrice} XOF/lb</span>
                  </p>
                </div>
                <div className="product-card-container">
                  <p>
                    <strong className="current-price">{product.price} XOF/ea</strong>
                  </p>
                  <div className="right-container">
                    <QuantitySelector
                      initialQuantity={quantities[product.name] || 0}
                      onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="image-column">
        <img src={productType.image} alt="Image des produits populaires" className="product-Image" />
      </div>
    </div>
  );
};

const ProductsParType: React.FC<{ products: Product[] }> = ({ products }) => {
  const [productTypes, setProductTypes] = useState<{ [key: string]: { id:string;title: string, description: string, image: string } }>({});
  const [productsByType, setProductsByType] = useState<{ [key: string]: Product[] }>({});
  const [shopByLabels, setShopByLabels] = useState<{ [key: string]: { label: string; color: string }[] }>({});
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const { cartItems, updateQuantity, addToCart, removeFromCart } = useCart();
  const [currentIndex, setCurrentIndex] = useState<{ [key: string]: number }>({});

  const [notification, setNotification] = useState<string | null>(null);

  const [productsPerRow, setProductsPerRow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setProductsPerRow(2); // 1 product per row on small screens
      } else if (window.innerWidth >= 992 && window.innerWidth <= 1140) {
        setProductsPerRow(2); // 2 products per row on medium screens
      } else if (window.innerWidth > 1140 && window.innerWidth <= 1493) {
        setProductsPerRow(3); // 3 products per row on larger screens
      } else {
        setProductsPerRow(4); // 4 products per row on very large screens
      }
    };
  
    // Initialize window size on load
    handleResize();
  
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  // Navigation vers les produits précédents
  const handlePrev = (productTypeId: string) => {
    setCurrentIndex((prevIndex) => ({
      ...prevIndex,
      [productTypeId]: Math.max(0, (prevIndex[productTypeId] || 0) - productsPerRow),
    }));
  };

  // Navigation vers les produits suivants
  const handleNext = (productTypeId: string) => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = Math.max(0, (productsByType[productTypeId]?.length || 0) - productsPerRow);
      return {
        ...prevIndex,
        [productTypeId]: Math.min(maxIndex, (prevIndex[productTypeId] || 0) + productsPerRow),
      };
    });
  };


  // Fetch product types
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const types = await getProductTypes();
        const typesMap = types.reduce<{ [key: string]: { id:string;title: string, description: string, image: string } }>((acc, type) => {
          acc[type.id] = { id:type.id,title: type.title, description: type.description, image: type.image };
          return acc;
        }, {});
        setProductTypes(typesMap);
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };
    fetchProductTypes();
  }, []);

  // Fetch products by type
  useEffect(() => {
    const fetchProductsByType = async () => {
      if (Object.keys(productTypes).length === 0) return;
      const productData: { [key: string]: Product[] } = {};
      for (const productTypeId of Object.keys(productTypes)) {
        const products = await getProductsByProductTypeId(productTypeId);
        productData[productTypeId] = products;
      }
      setProductsByType(productData);
    };
    fetchProductsByType();
  }, [productTypes]);

  // Handle adding product to cart with quantity

  useEffect(() => {
    const fetchShopByLabels = async () => {
      try {
        const labels: { [key: string]: { label: string; color: string }[] } = {};
        for (const productId of Object.keys(productsByType).flatMap(typeId => productsByType[typeId].map(p => p.id))) {
          const shopBys = await getShopByForProduct(productId);
          labels[productId] = shopBys.map((shopBy: any) => ({
            label: shopBy.label,
            color: shopBy.color
          }));
        }
        setShopByLabels(labels);
      } catch (error) {
        console.error("Error fetching ShopBy labels:", error);
      }
    };
    if (Object.keys(productsByType).length > 0) {
      fetchShopByLabels();
    }
  }, [productsByType]);

  return (
    <div>
      {notification && <div className="notification"><p>{notification}</p></div>}
      {Object.keys(productTypes).map((typeId) => {
        const productType = productTypes[typeId];
        const products = productsByType[typeId] || [];
        if (products.length === 0) return null;

        return (
          <ProductSection
            key={typeId}
            productType={productTypes[typeId]}
            products={productsByType[typeId] || []}
            currentIndex={currentIndex[typeId] || 0}
            productsPerRow={productsPerRow}
            shopByLabels={shopByLabels}
            quantities={quantities}

            handlePrev={() => handlePrev(typeId)}
            handleNext={() => handleNext(typeId)}

          />
        );
      })}
    </div>
  );
};


export default ProductsParType;
