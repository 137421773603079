

import ProductType from "../../types/ProductType";
import HTTP_CLIENT from "../client";

const API_ENDPOINT = "product_types";

// Récupérer tous les ProductTypes
export const getProductTypes = async (): Promise<ProductType[]> => {
  try {
    console.log("Requête pour récupérer tous les ProductTypes...");
    const response = await HTTP_CLIENT.get<{ data: ProductType[] }>(API_ENDPOINT);
    console.log("ProductTypes récupérés :", response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des ProductTypes:", error);
    throw error;
  }
};

// Récupérer un ProductType par son ID
export const getProductTypeById = async (id: string): Promise<ProductType> => {
  try {
    const response = await HTTP_CLIENT.get<{ data: ProductType }>(`${API_ENDPOINT}/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du ProductType:", error);
    throw error;
  }
};

// Créer un nouveau ProductType
export const createProductType = async (formData: FormData): Promise<ProductType> => {
  try {
    const response = await HTTP_CLIENT.post<{ data: ProductType }>(API_ENDPOINT, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data;
  } catch (error) {
    console.error("Erreur lors de la création du ProductType:", error);
    throw error;
  }
};

// Mettre à jour un ProductType existant
export const updateProductType = async (id: string, formData: FormData): Promise<ProductType> => {
  try {
    const response = await HTTP_CLIENT.put<{ data: ProductType }>(`${API_ENDPOINT}/${id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du ProductType:", error);
    throw error;
  }
};

// Supprimer un ProductType
export const deleteProductType = async (id: string): Promise<void> => {
  try {
    await HTTP_CLIENT.delete(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    console.error("Erreur lors de la suppression du ProductType:", error);
    throw error;
  }
};
