import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Breadcrumbs from '../Components/Categories/Breadcrumbs/Breadcrumbs';
import Product from '../Components/Categories/Product';
import SubcategoryList from '../Components/Categories/SubCategoriesList/SubCategoriesList';
import { getProductsBySubcategory } from '../api/requests/ProductRequests';
import { getSubcategoriesByCategory } from '../api/requests/subcategoryRequests';
import { getCategoryIdByLabel } from '../api/requests/categorieRequests';
import { getNavItemIdByLabel } from '../api/requests/navItemRequests';
const CategoryPage: React.FC = () => {
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');
  const [products, setProducts] = useState<any[]>([]);
  const [subcategories, setSubcategories] = useState<{ label: string, id: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [navItemId, setNavItemId] = useState<string>('');

  const { categoryLabel } = useParams();
  const location = useLocation();

  const navItemLabel = location.pathname.split('/')[2];
  const menuDataLabel = location.pathname.split('/')[3];

  const restoreOriginalLabel = (label: string) => {
    return label
      .replace(/-/g, '&')    // Remplacer les tirets par le caractère `&` (qui était remplacé dans le formatage)
      .replace(/_/g, ' ')    // Remplacer les underscores par des espaces (qui étaient des espaces avant formatage)
      .replace(/^-|-$/g, '') // Supprimer les tirets en début et fin de chaîne (si présents)
  };

  const restoreOriginalLabelbread = (label: string) => {
    return label
      .replace(/-/g, '&')    // Remplacer les tirets par le caractère `&` (qui était remplacé dans le formatage)
      .replace(/_/g, ' ')    // Remplacer les underscores par des espaces (qui étaient des espaces avant formatage)
      .replace(/^-|-$/g, '') // Supprimer les tirets en début et fin de chaîne (si présents)
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Mettre en majuscule la première lettre de chaque mot
  };

  // Fonction pour récupérer l'ID du NavItem
  const fetchNavItemId = async () => {
    if (navItemLabel) {
      try {
        const originelabel = restoreOriginalLabel(navItemLabel)
        const navItemId = await getNavItemIdByLabel(originelabel);
        if (!navItemId) {
          throw new Error('ID du NavItem introuvable');
        }
        setNavItemId(navItemId);
        console.log('NavItem ID fetched:', navItemId);
      } catch (err) {
        console.error("Erreur lors de la récupération de l'ID du NavItem:", err);
        setError('Erreur lors de la récupération de l\'ID du NavItem');
      }
    }
  };

  const fetchCategoryData = async () => {
    if (!categoryLabel) return;
  
    setLoading(true);
    setError('');
  
    try {
      const decodedCategoryLabel = categoryLabel; // Décodage du label
  
      // Fetch category ID avec le label décodé
      const id = await getCategoryIdByLabel(decodedCategoryLabel);
      console.log("Category ID fetched:", id); // Console log pour vérifier l'ID de la catégorie
  
      // Vérifier si l'ID est valide
      if (!id) {
        throw new Error("ID de la catégorie invalide");
      }
  
      setCategoryId(id); // ID de la catégorie récupéré et mis à jour dans l'état
  
      // Fetch des sous-catégories avec leurs ID
      const subcategoryData = await getSubcategoriesByCategory(id);
      console.log("Subcategories fetched:", subcategoryData); // Console log pour vérifier les sous-catégories
  
      if (subcategoryData.length === 0) {
        throw new Error("Aucune sous-catégorie trouvée pour cette catégorie");
      }
  
      const subcategoryLabelsWithIds = subcategoryData.map((sub) => ({
        label: sub.label,
        id: sub.id,
      }));
      setSubcategories(subcategoryLabelsWithIds); // Mettre à jour les sous-catégories
  
      // Fetch des produits pour toutes les sous-catégories
      const productPromises = subcategoryLabelsWithIds.map((subcategory) =>
        getProductsBySubcategory(subcategory.id) // Utilisation de l'ID de la sous-catégorie
      );
  
      // Ajout d'un bloc de gestion des erreurs pour chaque promesse
      const productResults = await Promise.all(
        productPromises.map(async (promise) => {
          try {
            const products = await promise;
            console.log("Products fetched for subcategory:", products); // Console log pour vérifier les produits
            return products;
          } catch (error) {
            console.error("Erreur lors du chargement des produits:", error);
            return []; // Retourne un tableau vide en cas d'erreur pour ne pas bloquer le processus
          }
        })
      );
  
      setProducts(productResults.flat()); // Mettre à jour la liste des produits
      console.log("All products fetched:", productResults.flat()); // Console log pour vérifier tous les produits récupérés
    } catch (err) {
      console.error('Erreur lors de la récupération des données de catégorie:', err);
      setError(`Erreur lors du chargement des données: `);
    } finally {
      setLoading(false);
    }
  };

  // Mettre à jour les breadcrumbs avec le label de la sous-catégorie sélectionnée
  const breadcrumbs = [
    { label: restoreOriginalLabelbread(navItemLabel) || '', path: `/department/${navItemLabel ? restoreOriginalLabel(navItemLabel).replace(/ /g, '-').replace(/&/g, '-') : ''}` },
    { label: menuDataLabel || '', path: `/${navItemLabel}/${menuDataLabel}` },
    { label: categoryLabel || '', path: location.pathname },
    // Ajouter le label de la sous-catégorie ici
    { label: selectedSubcategory || '', path: location.pathname + '/' + selectedSubcategory.toLowerCase().replace(/ /g, '-') },
  ];

  useEffect(() => {
    fetchNavItemId(); 
    fetchCategoryData();
  }, [categoryLabel]);

  const handleSubcategoryClick = async (label: string) => {
    setSelectedSubcategory(label);
  
    // Récupérer l'ID de la sous-catégorie sélectionnée
    const subcategory = subcategories.find(sub => sub.label === label);
    if (subcategory) {
      // Utiliser l'ID pour récupérer les produits associés
      const products = await getProductsBySubcategory(subcategory.id);
      console.log("Products fetched for selected subcategory:", products); // Console log pour vérifier les produits de la sous-catégorie sélectionnée
      setProducts(products);
    }
  };

  return (
    <main>
      {/* Affichage des breadcrumbs */}
      <Breadcrumbs breadcrumbs={breadcrumbs} totalProducts={products.length} />

      {/* Affichage de la liste des sous-catégories */}
      <SubcategoryList
        categoryLabel={categoryLabel || ''}
        subcategories={subcategories.map(sub => sub.label)} // Utilisation des labels des sous-catégories
        onSubcategoryClick={handleSubcategoryClick} // Mise à jour avec la nouvelle fonction
      />

      {/* Message de chargement */}
      {loading && <p>Chargement des produits...</p>}

      {/* Affichage de l'erreur */}
      {error && <p className="error-message">{error}</p>}

      {/* Affichage des produits ou message si aucun produit */}
      {!loading && !error && products.length === 0 && (
        <p>Aucun produit disponible pour cette sous-catégorie.</p>
      )}

      {!loading && !error && products.length > 0 && (
        <Product products={products} />
      )}
    </main>
  );
};

export default CategoryPage;
