import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DepartmentsSection.css';
import { getMenuDataByNavItemId } from '../../api/requests/menuDataRequests';
import { getCategoriesByMenuData } from '../../api/requests/categorieRequests';
import { getNavItemById } from '../../api/requests/navItemRequests';

interface Category {
  label: string;
  subcategories?: string[];
}

interface MenuData {
  label: string;
  categories: (string | Category)[];
  image: string;
}

interface DepartmentsSectionProps {
  navItemId: string; // Added navItemId prop to fetch data
}

const DepartmentsSection: React.FC<DepartmentsSectionProps> = ({ navItemId }) => {
  const [navItemLabel, setNavItemLabel] = useState<string>(''); // State for navItemLabel
  const [menuData, setMenuData] = useState<MenuData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (navItemId) {
      setLoading(true);
      fetchNavItemLabel(); // Fetch the label for the navItemId
      fetchMenuData(); // Fetch menu data associated with the navItemId
    } else {
      setLoading(false);
    }
  }, [navItemId]);

  const fetchNavItemLabel = async () => {
    try {
      const navItem = await getNavItemById(navItemId); // Récupérer le NavItem via l'ID
      setNavItemLabel(navItem.label); // Assigner le label à l'état
    } catch (error) {
      setError('Erreur lors de la récupération du NavItem');
      setLoading(false);
    }
  };

  const fetchMenuData = async () => {
    if (!navItemId) return;
    try {
      const data = await getMenuDataByNavItemId(navItemId);
      if (Array.isArray(data) && data.length > 0) {
        setMenuData(data);
        fetchCategoriesForMenuData(data[0].id);
      } else {
        setError('Aucune donnée trouvée pour ce navItemId.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des MenuData:', error);
      setError('Erreur lors de la récupération des MenuData');
      setLoading(false);
    }
  };

  const formatLabelForUrl = (label: string) => {
    return label
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/&/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const fetchCategoriesForMenuData = async (menuId: string) => {
    try {
      const data = await getCategoriesByMenuData(menuId);
      setCategories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
      setError('Erreur lors de la récupération des catégories');
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!menuData || menuData.length === 0) {
    return <div className="error-message">Aucune donnée disponible pour ce département.</div>;
  }

  const sectionPairs = [];
  for (let i = 0; i < menuData.length; i += 2) {
    sectionPairs.push(menuData.slice(i, i + 2));
  }

  return (
    <div className="departments-section">
      <div className="main-department-header">
        <h1 className="main-department-title">Ready-to-eat: {menuData.map(section => section.label).join(', ')}</h1>
      </div>
      <hr className="section-divider" />

      {sectionPairs.map((pair, pairIndex) => (
        <div key={pairIndex} className="departments-row">
          {pair.map((section: MenuData, sectionIndex) => {
            const sectionCategories = section.categories || [];

            return (
              <div key={sectionIndex} className={`department-section ${sectionIndex % 2 === 0 ? 'image-right' : 'image-left'}`}>
                <h2 className="department-title">{section.label}</h2>
                <div className="categories-container">
                  <div className="categories-list">
                    {categories.slice(0, 6).map((category, catIndex) => (
                      <span key={catIndex} className="category-item">
                        {category.label}
                        {catIndex < 5 && ', '}
                      </span>
                    ))}
                  </div>
                  <Link
                    to={`/${formatLabelForUrl(navItemLabel)}/${formatLabelForUrl(section.label)}`} // Use formatted navItemLabel
                    className="view-all-button"
                  >
                    View All
                  </Link>

                  {section.image && (
                    <img
                      src={section.image}
                      alt={section.label}
                      className="department-image"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default DepartmentsSection;
